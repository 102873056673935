<script setup lang="ts">
import { register } from 'swiper/element/bundle';
register();

import { ComsListLogoIF } from "@/types/Facet";
import { filename } from 'pathe/utils'

// @ts-ignore
const glob = import.meta.glob('~/assets/images/company_logo/*.gif', { eager: true });
const images = Object.fromEntries(
  Object.entries(glob).map(([key, value]: [string, any]) => [filename(key), value.default])
);

const comsLogo: ComsListLogoIF[] = [
    // Swiper バージョン9のループ挙動に不具合があるらしく、slidesPerViewの設定×2のスライド画像を用意しないと動かない
    {
      src: 'asahi',
      alt: '楽天損害保険'
    },
    {
      src: 'tokyokaijou',
      alt: '東京海上日動'
    },
    {
      src: 'aig',
      alt: 'AIG損保'
    },
    {
      src: 'ioi',
      alt: 'あいおいニッセイ同和損保'
    },
    {
      src: 'sompojapan',
      alt: '損保ジャパン'
    },
    {
      src: 'mitsuikaijou',
      alt: '三井住友海上'
    },
    {
      src: 'asahi',
      alt: '楽天損害保険'
    },
    {
      src: 'tokyokaijou',
      alt: '東京海上日動'
    },
    {
      src: 'aig',
      alt: 'AIG損保'
    },
    {
      src: 'ioi',
      alt: 'あいおいニッセイ同和損保'
    },
    {
      src: 'sompojapan',
      alt: '損保ジャパン'
    },
    {
      src: 'mitsuikaijou',
      alt: '三井住友海上'
    }
]
</script>

<template>
  <div>
    <div class="deviceisDesktop">
      <div class="comsList">
        <div v-for="(logo, idx) in comsLogo.slice(0, 6)" :key="idx" class="comsList-logo">
          <img :src="images['logo_' + logo.src]" :alt="logo.alt" width="230" height="112" />
        </div>
      </div>
    </div>
    <div class="deviceisMobile">
      <div class="comsList">
        <div class="comsList-title">参加保険会社</div>
        <Swiper
        :loop="true"
        :slidesPerView="'auto'"
        :speed="2000"
        :spaceBetween="0"
        :autoplay="{
          delay: 0,
          disableOnInteraction: false, 
        }"
        >
          <SwiperSlide
          v-for="(logo, idx) in comsLogo" :key="idx" 
          >
            <div class="comsList-logo">
              <img :src="images['logo_' + logo.src]" :alt="logo.alt" width="230" height="112" />
            </div>
          </SwiperSlide>
        </Swiper>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
:deep {
  .swiper-wrapper {
    transition-timing-function: linear!important;
  }
  .swiper-slide {
    width: 25.6vw !important;
    margin-right: 3vw;
  }
}

.comsList-title {
  margin-bottom: 3.2%;
  text-align: center;
}

.comsList-logo {
  img {
    width: 100%;
    border: 1px solid #cccccc;
  }
}

@include media(pc) {
  .comsList {
    margin: 0;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    padding: 23px 20px 27px;
    border-left: 2px solid $colorBlue;
    border-right: 2px solid $colorBlue;
    border-bottom: 2px solid $colorBlue;
    background: url(~/assets/images/bg_pattern_02.gif) no-repeat 0 0;
    background-size: 100% 100%;
  }

  .comsList-logo {
    margin: 0 0 16px 0;
    width: 80px !important;

    img {
      border: none;
    }
  }

  .comsList .comsList-logo:nth-child(n + 4) {
    margin-bottom: 0;
  }
}
</style>
